import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import CreditTypography from './CreditTypography'
import Track from './Track'
import {
  coverArt,
  redBackground1,
  redBackground2,
  redBackground3,
  redBackground4,
  redBackground5
} from '../assets/images'
import {
  track1,
  track2,
  track3,
  track4,
  track5
} from '../assets/audio'

class Dashboard extends React.Component {
  state = {
    openTrackId: 'notrack',
  }
  trackSelected = (id) => {
    if (id === this.state.openTrackId) {
      this.setState(() => ({ openTrackId: 'notrack' }))
    } else {
      this.setState(() => ({ openTrackId: id }))
    }
  }
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth='sm' style={{ textAlign: 'center' }}>
          <img src={coverArt} style={{ width: '100%', marginTop: 30 }} />
          <Track
            id='track1'
            title='Act 1'
            backgroundImage={redBackground1}
            audioSource={track1}
            trackSelected={this.trackSelected}
            openTrackId={this.state.openTrackId}
          />
          <Track
            id='track2'
            title='Act 2'
            backgroundImage={redBackground2}
            audioSource={track2}
            trackSelected={this.trackSelected}
            openTrackId={this.state.openTrackId}
          />
          <Track
            id='track3'
            title='Act 3'
            backgroundImage={redBackground3}
            audioSource={track3}
            trackSelected={this.trackSelected}
            openTrackId={this.state.openTrackId}
          />
          <Track
            id='track4'
            title='Act 4'
            backgroundImage={redBackground4}
            audioSource={track4}
            trackSelected={this.trackSelected}
            openTrackId={this.state.openTrackId}
          />
          <Track
            id='track5'
            title='Act 5'
            backgroundImage={redBackground5}
            audioSource={track5}
            trackSelected={this.trackSelected}
            openTrackId={this.state.openTrackId}
          />
          <Typography align='justify' style={{ paddingTop: 15 }}>
            <em>Valcapella and Dwinn</em> was first published in issue 5 of{' '}
            <Link href='https://www.theliftedbrow.com'>The Lifted Brow</Link>. Daniel Handler (aka
            Lemony Snicket) wrote this blurb for the inside front cover, next to where the CD was:
          </Typography>
          <Typography align='justify' style={{ paddingTop: 15 }}>
            "DON’T DO WHAT YOU USUALLY DO when there’s a CD included with a magazine, which is leave
            it in the little thing. Take this out of the thing and listen to it because it’s an epic
            of heartbreak and awesomeness, fully acted-out and music-ed, and it demands your
            attention so just listen to it. What’s wrong with you, if you’re one of the people not
            listening to it? Are you going to use the excuse of having a traumatic childhood
            experience with a recording of an epic poem? Or are you just a morally stunted person
            who does nothing but self-Google and snack? Get ahold of yourself. Take the CD out of
            the thing. Move one step forward to the heavenly glory available here on Earth instead
            of eating so many corn chips and scrolling lonesomely down the address book in your
            phone."
          </Typography>
          <Container style={{ paddingTop: 20, paddingBottom: 30 }}>
            <CreditTypography role='Written and Narrated by' nameText='Thomas Benjamin Guerney' />
            <CreditTypography role='Directed, Produced, and Music-ed by' nameText='Joff Bush' />
            <CreditTypography
              role='Starring'
              nameText="Hannah Perkins, Robbie O'brien, John McGeachin, Robert
                Bush, and Sarah Grey"
            />
            <CreditTypography role='Cover Art by' nameText='Bradley Parker' />
          </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default Dashboard
